import React from 'react';
import firebase from 'gatsby-plugin-firebase';
import Layout from 'components/layout';
import Box from 'components/box';

function Test() {
  const [data, setData] = React.useState([]);

  let projects = [];
  React.useEffect(() => {
    const fetchData = async () => {
      await firebase
        .firestore()
        .collection('projects')
        .orderBy('position')
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            projects.push({ id: doc.id, data: doc.data() });
          });
        });
      setData(projects);
    };
    fetchData();
  }, []);

  return (
    <Layout>
      <Box>
        {data
          ? data.map(e => {
              return (
                <div key={e.id} style={{ display: 'flex', flex: 'wrap' }}>
                  <div>
                    <p>{e.data.description}</p>

                    <img
                      src={e.data.img}
                      alt="toto"
                      style={{ width: '500px' }}
                    />
                  </div>
                </div>
              );
            })
          : 'Loading...'}
      </Box>
    </Layout>
  );
}

export default Test;
